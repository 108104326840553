import React from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { MyTextInput, MySelect, MyTextArea } from "../../../components/formik/FormikFields"; // Assuming the path is correct

function AddSupportRequests() {
    const navigate = useNavigate();

    const helpOptions = [
        { value: "Subscriptions", label: "Subscriptions" },
        { value: "Order", label: "Order" },
        { value: "Payment Method", label: "Payment Method" },
        { value: "Website Technical Issue", label: "Website Technical Issue" }
    ];

    return (
        <div className="pageView">
            <div className="pageWrapper">
                <Typography variant="h4" className={"pageTitle"}>
                    <div>
                        <button className={"backButton"} onClick={() => navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Add Support Request
                </Typography>

                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        phone: "",
                        helpType: "",
                        description: ""
                    }}
                    onSubmit={(values) => {
                        // Handle form submission
                        console.log("Form Submitted", values);
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form className="formContainer">
                            <MyTextInput
                                label="Name"
                                name="name"
                                required
                                fieldName="Name"
                                description="Please enter your full name."
                            />
                            <MyTextInput
                                label="Email"
                                name="email"
                                type="email"
                                required
                                fieldName="Email"
                                description="Enter your email address."
                            />
                            <MyTextInput
                                label="Phone"
                                name="phone"
                                required
                                fieldName="Phone"
                                description="Enter your phone number."
                            />
                            <MySelect
                                label="What do you want help with?"
                                name="helpType"
                                required
                                fieldName="Help Type"
                                description="Select the type of support you need."
                            >
                                <option value="">Select an option</option>
                                {helpOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </MySelect>
                            <MyTextArea
                                label="Support Request Description"
                                name="description"
                                required
                                rows={4}
                                fieldName="Description"
                                description="Provide more details about the issue or request."
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                style={{ marginTop: "20px" }}
                            >
                                Submit Request
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default AddSupportRequests;
