import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Typography } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { Link } from "react-router-dom";
import createCache from "@emotion/cache";
import generateSortableColumn from "../../components/functions/generateSortableColumn";
import { getStatusColor, getStatusIcon, MyChip } from "../orderList/ordersUtils";
import ConfirmationModal from "./ConfirmationModal";
import * as actions from "../../actions/actionTypes";

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const columns = [
    generateSortableColumn("Request ID", 0, { filter: false, sortThirdClickReset: true, sortDescFirst: false }),
    generateSortableColumn("Date", 1, { filter: false, sortThirdClickReset: true, align: "center", sortDescFirst: false }),
    generateSortableColumn("Status", 2, {
        filter: false, sortThirdClickReset: true, align: "center", sortDescFirst: false,
        customBodyRender: (value) => {
            const color = getStatusColor(value);
            const icon = getStatusIcon(value);
            const displayValue = value === "INPROGRESS" ? "IN-PROGRESS" : value;
            return (
                <span className={"statusIcon"}>
                    <MyChip label={displayValue} icon={icon} color={color} />
                </span>);
        }
    }),
    generateSortableColumn("Summary", 3, { filter: false, align: "center", sortThirdClickReset: true, sortDescFirst: false }),
    generateSortableColumn("Actions", 4, { filter: false, align: "center" })
];

const options = {
    filterType: "dropdown",
    selectableRows: "none",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    rowsPerPageOptions: [10, 15, 20, 25, 30],
    setRowProps: (row, dataIndex, rowIndex) => {
        return {
            style: {
                backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f0f0f1',
                fontSize: '18px'
            },
        };
    }
};

function AdminRequests(props) {
    const [requests, setRequests] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch(); // Use dispatch to reset issueAdded
    const jiraIssuesList = props.jiraIssuesList;
    const issueAdded = props.issueAdded;

    useEffect(() => {
        if(issueAdded){
            setModalOpen(true); // Open the modal when issueAdded is true
        }
    },[issueAdded]);

    const handleModalClose = () => {
        setModalOpen(false);
        setTimeout(() => {
            dispatch({ type: actions.RESET_ISSUE_ADDED });
        }, 0); // Use a timeout to ensure modal close action completes before resetting
    };

    useEffect(() => {
        if (jiraIssuesList && Array.isArray(jiraIssuesList)) {
            const formattedData = jiraIssuesList.map(issue => {
                if (issue && issue.fields && issue.fields.summary && issue.fields.status) {
                    const subscription = issue.fields.summary.split(',')[0]; // Takes only the first part before the comma
                    const summaryText = `Cancel ${subscription}`;

                    return {
                        id: issue.key,
                        date: new Date(issue.fields.created).toLocaleDateString(),
                        status: issue.fields.status.statusCategory.name.replace(/-/g, "").replace(/\s/g, "").toUpperCase(),
                        summary: summaryText,
                        actions: <div></div> // Placeholder for actions
                    };
                } else {
                    // If the issue is malformed or doesn't have expected fields, return a default object
                    return {
                        id: issue?.key || "Unknown ID",
                        date: "Unknown Date",
                        status: "Unknown Status",
                        summary: "No Summary Available",
                        actions: <div></div> // Placeholder for actions
                    };
                }
            });
            setRequests(formattedData);
        }
    }, [jiraIssuesList]);

    return (
        <div className="pageView">
            <div className="pageWrapper">
                <Typography variant="h4" className="pageTitle">Support Requests</Typography>
                <CacheProvider value={muiCache}>
                    <MUIDataTable
                        className="customTableHeader"
                        title={""}
                        data={requests.map(request => [
                            request.id,
                            request.date,
                            request.status,
                            request.summary,
                            request.actions // Added actions to data array
                        ])}
                        columns={columns}
                        options={options}
                    />
                </CacheProvider>
                <br/>
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    acknoledgementMsg={'Thank you! Your request has been submitted. Someone will be in contact in 1-2 business days.'}
                />
                <Link to={"/admin-requests/add-support-request"}>
                    <button className={"wooButtons"}> Add Support Request </button>
                </Link>
            </div>
        </div>
    );
}

export default AdminRequests;
