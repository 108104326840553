/**
 * deleteCompany Api is used to DELETE a Company from custom post type on WordPress
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios';
import * as actions from './actionTypes';
import {toastr} from "react-redux-toastr";
import { toast, ToastContainer } from 'react-toastify';

/**
 * DELETE a company from Wordpress Custom Post Type - Companies.
 * @endpoint "/delete-company"  Middleware customization and documentation.
 * @param userId Customer Login userID to get user specific details.
 * @param companyId CompanyId to get user specific details.
 * @return Response correct status if successful or not .
 */
const deleteCompanyCpt = (userId, companyId, nonce) => async (dispatch, getState) => {

    dispatch({ type: actions.DELETE_COMPANIES_REQUEST })

    try {
        const response = await axios.delete(
            '/companieslist', {
                params: {
                    userId: userId,
                    companyId: companyId,
                    nonce: nonce
                }
            }
        )
        const deleteCompanyId = response.data.id;

        dispatch({ type: actions.DELETE_COMPANIES_SUCCESS, payload: deleteCompanyId })

        if (deleteCompanyId) {
            toast.success('Successfully Deleted Company!');
        } else {
            toast.error('Failed to delete Company. Please try again!');
        }

    } catch (error) {
        dispatch({ type: actions.DELETE_COMPANIES_FAILURE, error })
        toast.error('Failed to delete Company. Please try again!');
    }
}

export default deleteCompanyCpt;
